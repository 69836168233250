
























































































// TODO: rewrite the component using class-based components syntax
import Vue from 'vue';

type TEventLanguageName = {
  [key: string]: string;
}

type TEventLanguage = {
  twoLetterCode: string;
  name: TEventLanguageName;
  necessary: boolean;
  selected: boolean;
}

type TVueClassNamesObject = {
  [key: string]: boolean;
}

interface IEventLanguageSelectorData {
  isPreSelectedLanguagePropProcessed: boolean;
  isOptionsListShown: boolean;
  languagesList: TEventLanguage[];
  selectedLanguages: TEventLanguage[];
  maxChoosableLanguages: number;
}

interface IEventLanguageSelectorMethods {
  showOptionsList: () => void;
  hideOptionsList: () => void;
  toggleOptionsList: () => void;
  optionClick: (lang: TEventLanguage) => void;
  getOptionNameTranslated: (lang: TEventLanguage) => string;
  getOptionClassNames: (lang: TEventLanguage) => TVueClassNamesObject;
  deleteSelectedLanguage: (lang: TEventLanguage) => void;
  emitResultingLanguages: () => void;
  initializeSelectedLanguageOnce: (input: Array<string>) => void;
}

interface IEventLanguageSelectorComputed {
  selectedLanguagesQuantity: number;
  necessaryLanguages: TEventLanguage[];
  availableLanguages: TEventLanguage[];
  resultingLanguages: string[];
}

interface IEventLanguageSelectorProps {
  preSelectedLanguages: string[];
  readOnly: boolean;
}

const MAX_CHOOSABLE_LANGUAGES = 3;

const EventLanguageSelector = Vue.extend<IEventLanguageSelectorData, IEventLanguageSelectorMethods, IEventLanguageSelectorComputed, IEventLanguageSelectorProps>({
  name: 'event-language-selector',
  components: {},
  props: {
    preSelectedLanguages: {
      type: Array,
      default(): string[] {
        return this.necessaryLanguages.map((lang) => lang.twoLetterCode);
      }
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    preSelectedLanguages: {
      handler(newValue: Array<string>): void {
        this.initializeSelectedLanguageOnce(newValue);
      }
    }
  },
  computed: {
    selectedLanguagesQuantity(): number {
      return this.necessaryLanguages.length + this.selectedLanguages.length;
    },
    necessaryLanguages(): Array<TEventLanguage> {
      return this.languagesList.filter((lang: TEventLanguage) => {
        return lang.necessary === true;
      });
    },
    availableLanguages(): Array<TEventLanguage> {
      return this.languagesList.filter((lang: TEventLanguage) => {
        return lang.necessary === false;
      });
    },
    resultingLanguages(): Array<string> {
      return this.necessaryLanguages.concat(this.selectedLanguages).map((lang: TEventLanguage) => lang.twoLetterCode);
    }
  },
  data(): IEventLanguageSelectorData {
    return {
      isPreSelectedLanguagePropProcessed: false,
      isOptionsListShown: false,
      maxChoosableLanguages: MAX_CHOOSABLE_LANGUAGES,
      languagesList: [
        {
          twoLetterCode: 'en',
          name: { // TODO: replace with TranslateResult $t('languages.english')
            en: 'English',
            ru: 'Английский',
            lt: 'Anglų',
            zh: '英語',
            uk: 'Англійська',
            vi: 'Tiếng Anh',
            bg: 'Английски',
            sq: 'Anglisht',
          },
          necessary: true,
          selected: true,
        },
        {
          twoLetterCode: 'lt',
          name: { // TODO: replace with TranslateResult $t('languages.lithuanian')
            en: 'Lithuanian',
            ru: 'Литовский',
            lt: 'Lietuvių',
            zh: '立陶宛语',
            uk: 'Литовська',
            vi: 'Tiếng Lithuania',
            bg: 'Литовски',
            sq: 'Lituaneze',
          },
          necessary: false,
          selected: false,
        },
        {
          twoLetterCode: 'ru',
          name: { // TODO: replace with TranslateResult $t('languages.russian')
            en: 'Russian',
            ru: 'Русский',
            lt: 'Rusų',
            zh: '俄語',
            uk: 'Російська',
            vi: 'Tiếng Nga',
            bg: 'Руски',
            sq: 'Rusisht',
          },
          necessary: false,
          selected: false,
        },
        {
          twoLetterCode: 'zh',
          name: { // TODO: replace with TranslateResult $t('languages.chinese')
            en: 'Chinese',
            ru: 'Китайский',
            lt: 'Kinų',
            zh: '中文',
            uk: 'Китайська',
            vi: 'Tiếng Trung Quốc',
            bg: 'Китайски',
            sq: 'Kineze',
          },
          necessary: false,
          selected: false,
        },
        {
          twoLetterCode: 'uk',
          name: { // TODO: replace with TranslateResult $t('languages.ukrainian')
            en: 'Ukrainian',
            ru: 'Украинский',
            lt: 'Ukrainietis',
            zh: '乌克兰',
            uk: 'Українська',
            vi: 'Tiêng Ukraina',
            bg: 'Украински',
            sq: 'Ukrainase',
          },
          necessary: false,
          selected: false,
        },
        {
          twoLetterCode: 'vi',
          name: { // TODO: replace with TranslateResult $t('languages.vietnamese')
            en: 'Vietnamese',
            ru: 'Вьетнамский',
            lt: 'Ukrainietis',
            zh: '越南語',
            uk: 'В\'єтнамська',
            vi: 'Tiếng Việt',
            bg: 'Виетнамски',
            sq: 'Vietnameze',
          },
          necessary: false,
          selected: false,
        },
        {
          twoLetterCode: 'bg',
          name: { // TODO: replace with TranslateResult $t('languages.bulgarian')
            en: 'Bulgarian',
            ru: 'Болгарский',
            lt: 'Bulgarų',
            zh: '保加利亞語',
            uk: 'Болгарська',
            vi: 'Tiếng Bulgaria',
            bg: 'Български',
            sq: 'Bullgare',
          },
          necessary: false,
          selected: false,
        },
        {
          twoLetterCode: 'sq',
          name: { // TODO: replace with TranslateResult $t('languages.albanian')
            en: 'Albanian',
            ru: 'Албанский',
            lt: 'Albanų',
            zh: '阿尔巴尼亚语',
            uk: 'Албанська',
            vi: 'Tiếng Albania',
            bg: 'Албански',
            sq: 'Shqipe',
          },
          necessary: false,
          selected: false,
        },
      ],
      selectedLanguages: [],
    };
  },

  mounted() {
    document.addEventListener('click', this.hideOptionsList);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.hideOptionsList);
  },

  methods: {

    showOptionsList(): void {
      this.isOptionsListShown = true;
    },

    hideOptionsList(): void {
      this.isOptionsListShown = false;
    },

    toggleOptionsList(): void {
      this.isOptionsListShown = !this.isOptionsListShown;
    },

    initializeSelectedLanguageOnce(input: Array<string>): void {
      // TODO: refactor
      // This is to be run only once, otherwise recursion may occur.
      if(this.isPreSelectedLanguagePropProcessed) {
        return;
      }

      for (let i = 0; i < input.length; i++) {
        for (let j = 0; j < this.languagesList.length; j++) {
          if (input[i] === this.languagesList[j].twoLetterCode && !this.languagesList[j].necessary) {
            this.optionClick(this.languagesList[j]);
          }
        }
      }
      this.isPreSelectedLanguagePropProcessed = true;
    },

    /* Actions on option click
     */
    optionClick(lang: TEventLanguage): void {

      if (lang.necessary || (!lang.selected && this.selectedLanguagesQuantity >= this.maxChoosableLanguages)) {
        return;
      }

      // Without this, first click on any option does two clicks because the watcher is getting run.
      this.isPreSelectedLanguagePropProcessed = true;

      if (lang.selected) {
        this.deleteSelectedLanguage(lang);
      } else {
        lang.selected = true;
        this.selectedLanguages.push(lang);
        this.emitResultingLanguages();
      }

    },

    /* Emit array of two-letter codes
     */
    emitResultingLanguages(): void {
      this.$emit('eventLanguagesUpdated', this.resultingLanguages);
    },

    /* Selected language removal
     */
    deleteSelectedLanguage(lang: TEventLanguage): void {
      lang.selected = false;
      this.selectedLanguages = this.selectedLanguages.filter((x) => {
        return x.twoLetterCode !== lang.twoLetterCode;
      });
      this.emitResultingLanguages();
    },

    /* Gets translated country name from a country data object
     */
    getOptionNameTranslated(lang: TEventLanguage): string {
      let result = lang.name.en; // English translation is default and is supposed to be a must-have

      const pageLang = this.$i18n.locale;
      if (lang.name[pageLang]) {
        result = lang.name[pageLang];
      }
      return result;
    },

    /* Returns dynamic class names for .custom-option :class
     */
    getOptionClassNames(lang: TEventLanguage): TVueClassNamesObject {
      const result: TVueClassNamesObject = {};

      // Dynamic class for styling each language separately
      result['custom-option-' + lang.twoLetterCode] = true; // We always need this dynamic className

      // Is it currently selected?
      result['custom-option-selected'] = lang.selected;

      // Make options unclickable after choice limit has been reached
      result['custom-option-unavailable'] = !lang.selected && (this.selectedLanguagesQuantity >= this.maxChoosableLanguages) && lang.necessary === false;

      return result;
    }

  }
});
export default EventLanguageSelector;
