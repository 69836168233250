import i18n from '@/i18n';

type TMuseUIDatepickerTexts = {
  firstDay: number;
  dayAbbreviation: [string, string, string, string, string, string, string];
  dayList: [string, string, string, string, string, string, string];
  monthList: [string, string, string, string, string, string, string, string, string, string, string, string];
  monthLongList: [string, string, string, string, string, string, string, string, string, string, string, string];
}

export type TMuseUIDatepickerDateTimeFormat = {
  formatDisplay: (date: Date) => string;
  formatMonth: (date: Date) => string;
  getWeekDayArray: () => string[];
  getMonthList: () => string[];
  formatDateDisplay: (date: Date) => string;
}

export default class DatepickerHelper {

  public static getMuseUIDatepickerDateTimeFormat(): TMuseUIDatepickerDateTimeFormat {

    const TEXTS: TMuseUIDatepickerTexts = this.getMuseUIDatepickerTexts();

    return {
      formatDateDisplay(date: Date): string {
        return `${date.getDate()} ${TEXTS.monthList[date.getMonth()]}`;
      },
      formatDisplay(date: Date): string {
        return `${TEXTS.dayList[date.getDay()]}, ${TEXTS.monthList[date.getMonth()]} ${date.getDate()}`;
      },
      formatMonth(date: Date): string {
        return `${TEXTS.monthLongList[date.getMonth()]} ${date.getFullYear()}`;
      },
      getMonthList(): string[] {
        return TEXTS.monthList;
      },
      getWeekDayArray(): string[] {
        const firstDayOfWeek = TEXTS.firstDay ? TEXTS.firstDay : 0; // firstDay or fallback to Sunday
        const beforeArray = [];
        const afterArray = [];
        for (let i = 0; i < TEXTS.dayAbbreviation.length; i++) {
          if (i < firstDayOfWeek) {
            afterArray.push(TEXTS.dayAbbreviation[i]);
          } else {
            beforeArray.push(TEXTS.dayAbbreviation[i]);
          }
        }
        return beforeArray.concat(afterArray);
      }
    };
  }

  public static getFirstDayOfWeekNumber(): number {
    const textData: TMuseUIDatepickerTexts = this.getMuseUIDatepickerTexts();
    return textData.firstDay;
  }

  /* mu-datepicker translation
   * https://muse-ui.org/#/en-US/date-input
   * N.B. formatDateDisplay method is NOT DOCUMENTED!
   */
  private static getMuseUIDatepickerTexts(): TMuseUIDatepickerTexts {

    // TODO: check against { APP_AVAILABLE_LANGUAGES } from '@/i18n' and use default locale if check failed

    return {
      firstDay: parseInt(i18n.t('datepicker.firstDayNumber') as string, 10),
      dayAbbreviation: [
        i18n.t('datepicker.dayAbbreviations.sunday') as string,
        i18n.t('datepicker.dayAbbreviations.monday') as string,
        i18n.t('datepicker.dayAbbreviations.tuesday') as string,
        i18n.t('datepicker.dayAbbreviations.wednesday') as string,
        i18n.t('datepicker.dayAbbreviations.thursday') as string,
        i18n.t('datepicker.dayAbbreviations.friday') as string,
        i18n.t('datepicker.dayAbbreviations.saturday') as string,
      ],
      dayList: [
        i18n.t('datepicker.dayList.sunday') as string,
        i18n.t('datepicker.dayList.monday') as string,
        i18n.t('datepicker.dayList.tuesday') as string,
        i18n.t('datepicker.dayList.wednesday') as string,
        i18n.t('datepicker.dayList.thursday') as string,
        i18n.t('datepicker.dayList.friday') as string,
        i18n.t('datepicker.dayList.saturday') as string,
      ],
      monthList: [
        i18n.t('datepicker.monthNamesShort.january') as string,
        i18n.t('datepicker.monthNamesShort.february') as string,
        i18n.t('datepicker.monthNamesShort.march') as string,
        i18n.t('datepicker.monthNamesShort.april') as string,
        i18n.t('datepicker.monthNamesShort.may') as string,
        i18n.t('datepicker.monthNamesShort.june') as string,
        i18n.t('datepicker.monthNamesShort.july') as string,
        i18n.t('datepicker.monthNamesShort.august') as string,
        i18n.t('datepicker.monthNamesShort.september') as string,
        i18n.t('datepicker.monthNamesShort.october') as string,
        i18n.t('datepicker.monthNamesShort.november') as string,
        i18n.t('datepicker.monthNamesShort.december') as string,
      ],
      monthLongList: [
        i18n.t('datepicker.monthNamesFull.january') as string,
        i18n.t('datepicker.monthNamesFull.february') as string,
        i18n.t('datepicker.monthNamesFull.march') as string,
        i18n.t('datepicker.monthNamesFull.april') as string,
        i18n.t('datepicker.monthNamesFull.may') as string,
        i18n.t('datepicker.monthNamesFull.june') as string,
        i18n.t('datepicker.monthNamesFull.july') as string,
        i18n.t('datepicker.monthNamesFull.august') as string,
        i18n.t('datepicker.monthNamesFull.september') as string,
        i18n.t('datepicker.monthNamesFull.october') as string,
        i18n.t('datepicker.monthNamesFull.november') as string,
        i18n.t('datepicker.monthNamesFull.december') as string,
      ],
    };

  }

}
